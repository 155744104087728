!<!-- global -->
<template>
  <div class='global' >
	<div style="width:100%;height:100%;" ref="chartsDOM">
    
  </div>
  <div 	class="timeInput">
    <el-date-picker
          v-model="value1"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
		  value-format="yyyy-MM"
          end-placeholder="结束月份">
        </el-date-picker>
    <el-button type='primary' @click='searchQuerys' icon='search' style="margin-left: 20px;" >查询</el-button>
  </div>
  <div class="sumMoney">
    营业额:{{sumMoney}}
  </div>
	  </div>
  </div>
</template>

<script>
	import * as echarts from 'echarts';
  import mapObj from '@/utils/map.js'
  export default {
    data() {
      return {
        totalInfo:{},
        loading:true,
        value1:'',
        map:{},
        // seriesData:mapObj.serData,
        startTime:'',
        endTime:'',
        seriesList:[],
        sumMoney:0,
        timers:{},
        option:{},
        myChart:{}
      };
    },
    computed: {},
    watch: {},
    methods: {
      async searchQuerys(){
        let res =await this.searchQuery()
         this.seriesList = res
         this.option.series[0].data = this.seriesList
         this.option && this.map.setOption(this.option)
         this.map.resize()
      },
      async searchQuery(){
        let res=await this.citys()
        let  geoCoordMap = mapObj.geoCoordMap
        let seriesList = []
        return new Promise(async (resolve,reject)=>{
          res.forEach((v,i)=>{
            if(i != res.length -1){
              let name =v.cityName
              let city = geoCoordMap[name]
              let obj ={
                   name:v.cityName,
                   value:city,
                   number:v.xdTotalAmount
              }
              seriesList.push(obj)
            }else{
               this.sumMoney = res[res.length -1].xdTotalAmount
            }
            resolve(seriesList)
          })
        })
      },
      citys(){
        let params={
			startTime:'',
			endTime:''
		}
		let value1 =this.value1
		console.log(value1)
        if(value1 && value1[0]){
          params.startTime=value1[0]+'-01'
        }
        if(value1 && value1[1]){
          params.endTime=value1[1]+'-01'
        }
		console.log(this.ajax)
        return new Promise(async (resolve,reject)=>{
           let res=await this.ajax.get(`/finance/sysxhdjFundsLog/notTokenCityServicestatistics?startTime=${params.startTime}&endTime=${params.endTime}`)
           resolve(res.data.result) 
        })
        
      },
      async echarsQuery(){
        let res =await this.searchQuery()
         this.seriesList = res
         this.loading =false
         var myChart = echarts.init(this.$refs["chartsDOM"]);
         console.log(myChart)
         this.map =myChart
        
          myChart.showLoading();
              // 得到结果后，关闭动画
              myChart.hideLoading();
              // 注册地图(数据放在axios返回对象的data中哦)
              echarts.registerMap('GX', mapObj.map);
              this.option = {
                  // 标题
                  title: {
                      text:"",
                      left: 'center',
        			  textStyle:{
        				  color:'#62A7C0'
        			  },
        			  top:'20',
                      // subtext: "下载链接",
                      sublink: "http://datav.aliyun.com/tools/atlas/#&lat=30.772340792178525&lng=103.94573258937584&zoom=15"
                  },
        		  geo: {
        				map: "china",
        				roam: false, // 关闭拖拽
        				zoom: 1,
        				center: [ '50%', '50%' ], // 调整地图位置
        				label: {
        					normal: {
        						show: false //关闭省份名展示
        					},
        					emphasis: {
        						show: false
        					}
        				},
        				itemStyle: {
        					normal: {
        						borderWidth: 3, //设置外层边框
        						borderColor: "#4272B8", //边界线颜色
        						 areaColor: {
        							  //地图色
        							  type: "linear",
        							  x: 0,
        							  y: 1,
        							  x2: 0,
        							  y2: 0,
        							  colorStops: [
        								{
        								  offset: 0,
        								  color: "#101C36", // 0% 处的颜色
        								},
        								{
        								  offset: 0.2,
        								  color: "#15233D", // 0% 处的颜色
        								},
        								{
        								  offset: 0.5,
        								  color: "#1E324B",
        								},
        								{
        								  offset: 1,
        								  color: "#1F3952", // 100% 处的颜色
        								},
        							  ],
        							  global: false, // 缺省为 false
        							},
        					},
        					emphasis: {
        						areaColor: "transparent",
        						shadowOffsetX: 0,
        						shadowOffsetY: 0,
        						borderWidth: 1,
        						borderColor: 'rgba(255, 255, 255, 1)'
        					}
        				}
        			},
                  // 悬浮窗
                  tooltip: {
                    color: "#fff",
                    textAlign: "center",
        			backgroundColor:'transparent',
        			formatter: function(params) {
        				let data = `
        				  <div class="map-tooltips">
        						 <div class="name">城市：${params.name}</div>
        						 <div class="value">
        						  <span class="num">营业额：${params.data.number}</span>
        						  <span class="unit">元</span>
        						 </div>
        				  </div>
        				`;
        				return data;
        			},
                  },
                  // 要显示的散点数据
                  series: [
                      {
                          type: 'effectScatter',
                          // 这是要显示的数据
                          data:this.seriesList,
                          coordinateSystem: "geo", //设置坐标系为 geo
        					label: {
        						formatter: "{b}",
        						position: "right",
        						show: true
        					},
        					effectType: 'ripple',
        					  showEffectOn: 'render',
        					  // 散点样式
        					  rippleEffect: {
        					    period: 1,
        					    scale: 2,
        					    brushType: 'fill'
        					  },
        					  // 散点大小
        					  symbolSize: [20,20],
        					  hoverAnimation: true,
        					  // 标志的样式
        					  itemStyle: {
        					    normal: {
        					      color: 'rgba(255, 235, 59, .7)',
        					      shadowBlur: 10,
        					      shadowColor: '#333'
        					    }
        					  },
        					  zlevel: 1,
                      },
                     
                  ]
              }
        	  // 将配置应用到地图上
        	  myChart.setOption(this.option);
        	 window.addEventListener('resize', function () {
        	     myChart.resize();
        	   }) 
      }
    },
    destroyed() {
      clearInterval(this.timers)
    },
    async mounted() {
      this.echarsQuery()
      this.timers= setInterval(()=>{
         this.echarsQuery()
      },10000)
    }
  }
</script>
<style  scoped>
  .global{
    height: 100vh;
    width: 100%;
	overflow: hidden;
  position: relative;
  /*  display: flex;
    align-items: center;
    justify-content: center; */
	background: #101D49;
     
  }
  ::v-deep .map-tooltips {
     min-width: p2r(160);
     position: relative;
     text-align: left;
     /* padding-top: p2r(10); */
	 background: #122C51;
	 box-sizing: border-box;
	 border: 1px solid #359EAA;
	 padding: 10px;
     .name {
       font-size: p2r(16);
       font-weight: 400;
       color: white;
       line-height: p2r(22);
       margin-bottom: p2r(8);
     }
      
     .value {
       color: white;
      
       .num {
         font-size: p2r(26);
         font-weight: bold;
         line-height: p2r(26);
       }
      
       .unit {
         font-size: p2r(14);
       }
     }
   }
  .timeInput{
	  position: absolute;
	  right: 20px;
	  top:50px;
    display: flex;
    align-items: center;
    z-index: 99;
  }
  .sumMoney{
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     top: 50px;
     font-size: 32px;
     color: #D4C53A;
     line-height:32px;
     font-weight: 700;
  }
</style>